import { For, Show, createRenderEffect } from "solid-js";

import type { Geography, ProgramRefWithLocation } from "~/types/drupal_jsonapi";
import PitchimmoBanner from "~/components/shared/PitchimmoBanner";
import { useSearchParams } from "@solidjs/router";
import { createStore } from "solid-js/store";
import {
  Pagination,
  paginationSlices,
  type ProgramPaginationStore,
} from "~/components/shared/Pagination";
import ProgramsList from "~/components/shared/ProgramsList";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import Card from "~/components/Cards/Card";
import { getCanonicalPathFromMetaData } from "~/utils/tools";
import { urlRs } from "~/utils/url";

const debug = false;

const [store, setStore] = createStore<ProgramPaginationStore>({
  sort: "cp",
  slices: [],
  currentPage: 0,
  displayedPrograms: [],
});

export default function GeographyPrograms(props: { geography: Geography }) {
  const settings = useDrupalSettingsContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const path = () => {
    const dest = getCanonicalPathFromMetaData(props.geography.metatag);
    const suffix = store.sort === "ville" ? "?sort=ville" : "";
    return urlRs("geographies", dest + suffix, settings);
  };

  const sortFn = (a: ProgramRefWithLocation, b: ProgramRefWithLocation) => {
    if (store.sort === "cp") {
      return a.postal_code.localeCompare(b.postal_code);
    } else {
      return a.city.localeCompare(b.city);
    }
  };

  createRenderEffect(() => {
    setStore("currentPage", parseInt(searchParams.page || "0"));
  });

  createRenderEffect(() => {
    setStore("sort", (searchParams.sort as "cp" | "ville") || "cp");
  });

  const showPromoCard = () => {
    return settings.promo_card.disp_geographies && store.currentPage === 0;
  };

  createRenderEffect(() => {
    const slices = paginationSlices(
      props.geography.programs!.toSorted(sortFn),
      {
        insertPromoCard: settings.promo_card.disp_geographies,
      },
    );
    setStore("slices", slices);
  });

  createRenderEffect(() => {
    setStore(
      "displayedPrograms",
      store.slices[store.currentPage].filter(Boolean),
    );
  });

  return (
    <>
      <section class="geography-programs" data-test="programs-geography">
        <div class="content-part">
          <Show when={debug}>
            <ul>
              <li>Path: {path()}</li>
              <li>Sort: {store.sort}</li>
              <li>Page: {store.currentPage}</li>
              <li>Slices len: {store.slices.length}</li>
              <li>
                Slices:{" "}
                {JSON.stringify(store.slices.map((s) => s.map((p) => p.id)))}
              </li>
              <li>
                DisplayedProgramsLength: {store.displayedPrograms?.length}
              </li>
              <li>
                Programs nids:{" "}
                {store.displayedPrograms?.map((p) => p.id).join(", ")}
              </li>
              <li>
                Programs cp:{" "}
                {store.displayedPrograms?.map((p) => p.postal_code).join(", ")}
              </li>
              <li>
                Programs city:{" "}
                {store.displayedPrograms?.map((p) => p.city).join(", ")}
              </li>
              <li>
                All Programs nids:{" "}
                {props.geography.programs?.map((p) => p.id).join(", ")}
              </li>
            </ul>
          </Show>
          <Show
            when={
              props.geography.programs && props.geography.programs.length > 0
            }
            fallback={
              <p class="h2">
                Nous n'avons actuellement pas de programme disponible{" "}
                {props.geography.field_prefix_1}
                {props.geography.name}.
              </p>
            }
          >
            <Show
              when={
                props.geography.geography_level === "region" ||
                props.geography.geography_level === "department"
              }
            >
              <div class="sort" data-test="sort">
                Trier par :
                <button
                  type="button"
                  classList={{ active: store.sort !== "ville" }}
                  onClick={() => {
                    setSearchParams({
                      page: undefined,
                      r: "s",
                      sort: undefined,
                    });
                    setStore("sort", "cp");
                  }}
                  data-test="filter-postal-code"
                >
                  Code postal
                </button>
                <button
                  type="button"
                  classList={{ active: store.sort === "ville" }}
                  onClick={() => {
                    setSearchParams({
                      page: undefined,
                      r: "s",
                      sort: "ville",
                    });
                    setStore("sort", "ville");
                  }}
                  data-test="filter-city"
                >
                  Ville
                </button>
              </div>
            </Show>
            <section
              id="programs"
              class="programs-list"
              data-ga-zone="list"
              data-test="programs"
            >
              <ProgramsList
                programs={store.displayedPrograms}
                showPromoCard={showPromoCard()}
              />
            </section>

            <Show when={store.slices.length > 1}>
              <Pagination
                currentPage={store.currentPage}
                totalPages={store.slices.length}
                url={path()!}
                type="geographies"
                scrollTo="#programs"
              />
            </Show>
          </Show>

          <Show
            when={
              props.geography.geography_level === "city" &&
              props.geography.other_programs_in_department &&
              props.geography.other_programs_in_department.length > 0
            }
          >
            <section class="other-department">
              <h2 class="title">Nos autres résidences dans ce département</h2>
              <section
                class="programs-list"
                data-ga-zone="other-programs-list"
                data-test="programs"
              >
                <For each={props.geography.other_programs_in_department}>
                  {(program) => (
                    <Card
                      nid={program.id}
                      item={{
                        id: program.id,
                        title: program.title,
                        url: program.url,
                      }}
                    />
                  )}
                </For>
              </section>
            </section>
          </Show>

          <PitchimmoBanner url={props.geography.path.alias.concat("/")} />
        </div>
      </section>
    </>
  );
}
